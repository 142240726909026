import Spinner from "react-bootstrap/Spinner";

import React from "react";

export default function Loading({ small }) {
  return (
    <div>
      {/* This extra div is necessary for react-snap to work */}
      <div
        style={{
          paddingTop: "6rem",
          textAlign: "center",
        }}
      >
        <Spinner animation="grow" variant="primary" size={small ? "sm" : ""} />
      </div>
    </div>
  );
}
