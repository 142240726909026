import "themes/bootstrap-theme.scss";
import "./index.scss";

// Support IE 11+
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React, { useState, useEffect, Suspense } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import {
  BrowserRouter as Router,
  useRouteMatch,
  Route,
  Switch,
} from "react-router-dom";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";

import { Helmet } from "react-helmet";

import TagManager from "react-gtm-module";

import Loading from "components/Loading";

import { routes, supportedLocales } from "./config";

const defaultLocale = supportedLocales[0];

export const AppContext = React.createContext();

function App() {
  // Set up states
  const match = useRouteMatch();
  let locale = match.params.locale;
  if (!locale) {
    locale = navigator.language || navigator.userLanguage; // for IE
    locale = locale.toLowerCase();
    if (!supportedLocales.includes(locale)) {
      locale = locale.split(/[-_]/)[0];
      if (!supportedLocales.includes(locale)) locale = defaultLocale;
    }
  }
  const [currentLocale, setCurrentLocale] = useState(locale);
  const [messages, setMessages] = useState();
  useEffect(() => {
    if (locale !== defaultLocale) {
      setCurrentLocale(locale);
      setMessages(null);
      import(`i18n/${locale}.js`).then((module) => {
        setMessages(module.default);
      });
    }
  }, [locale]); // update `currentLocale` only if `locale` changes

  if (locale !== defaultLocale && !messages) return <Loading />;

  // Set up React-intl
  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale: currentLocale,
      messages: messages,
    },
    cache
  );

  // Set up client-side routing
  let prefix = match.url; // matched portion of URL
  if (!prefix.endsWith("/")) prefix = prefix + "/";

  // Set up AppContext value
  const appContext = {
    supportedLocales: supportedLocales,
    currentLocale: currentLocale,
    intl: intl,
    urlPrefix: prefix,
  };

  return (
    <AppContext.Provider value={appContext}>
      <RawIntlProvider value={intl}>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: "index.title",
              defaultMessage: "Flora - Green Focus",
            })}
          </title>
          <meta name="author" content="AppFinca Inc." />

          {/* Google SEO */}
          <meta
            name="description"
            content={intl.formatMessage({
              id: "index.description",
              defaultMessage:
                "Flora is a free productivity app that helps you stay off your phone, clear to-do lists, and build positive, life-changing habits. Whenever you want to make progress towards your goals, grow trees in Flora!",
            })}
          />
          <link
            rel="alternate"
            href="https://flora.appfinca.com"
            hreflang="x-default"
          />
          {supportedLocales.map((locale, index) => (
            <link
              key={index}
              rel="alternate"
              href={"https://flora.appfinca.com/" + locale}
              hreflang={locale}
            ></link>
          ))}
          <script type="application/ld+json">
            {`{
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "${intl.formatMessage({
              id: "index.title",
              defaultMessage: "Flora - Green Focus",
            })}",
            "url": "https://flora.appfinca.com",
            "logo": "https://flora.appfinca.com/static/media/icon-512.png",
            "contactPoint" : [{
              "@type" : "ContactPoint",
              "url": "https://flora.appfinca.com/faq",
              "email": "support@flora.appfinca.com",
              "contactType": "customer support"
            }],
            "sameAs": [
              "https://www.facebook.com/FloraAppfinca",
              "https://twitter.com/FloraApp"
            ]
          }`}
          </script>
          <script type="application/ld+json">
            {`{
            "@context": "http://schema.org",
            "@type": "Country",
            "address": {
              "addressCountry": "US"
            }
          }`}
          </script>

          {/* Facebook Open Graph */}
          <meta property="fb:app_id" content="100749660278765" />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={"https://flora.appfinca.com" + prefix}
          />
          <meta property="og:site_name" content="Flora" />
          <meta
            property="og:title"
            content={intl.formatMessage({
              id: "index.social.title",
              defaultMessage: "Flora - Green Focus",
            })}
          />
          <meta
            property="og:description"
            content={intl.formatMessage({
              id: "index.social.description",
              defaultMessage:
                "A pleasant way to stay off the phone, clear to-dos, and build new habits.",
            })}
          />
          <meta
            property="og:image"
            content="https://flora.appfinca.com/static/media/social.png"
          />
          <meta property="og:locale" content={locale} />

          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@FloraApp" />
          <meta
            name="twitter:title"
            content={intl.formatMessage({
              id: "index.social.title",
              defaultMessage: "Flora - Green Focus",
            })}
          />
          <meta
            name="twitter:description"
            content={intl.formatMessage({
              id: "index.social.description",
              defaultMessage:
                "A pleasant way to stay off the phone, clear to-dos, and build new habits.",
            })}
          />
          <meta
            name="twitter:image"
            content="https://flora.appfinca.com/static/media/social.png"
          />
        </Helmet>

        <Suspense fallback={<Loading small />}>
          <Switch>
            {Object.keys(routes).map((path, i) => (
              <Route
                key={i}
                exact
                path={`${prefix}${path}`}
                component={routes[path]}
              />
            ))}
            <Route component={routes["404"]} />
          </Switch>
        </Suspense>
      </RawIntlProvider>
    </AppContext.Provider>
  );
}

const rootEl = document.getElementById("root");
let render = ReactDOM.render;
if (rootEl.hasChildNodes()) {
  render = ReactDOM.hydrate;
}
render(
  <Router>
    <Switch>
      <Route path={`/:locale(${supportedLocales.join("|")})`} component={App} />
      <Route component={App} />
    </Switch>
  </Router>,
  rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Google Tag Manager. Add Google Universal Analytics tags or Google Ads
// Conversion Tracking tags on GTM.
TagManager.initialize({
  gtmId: "GTM-PZQ6LWF",
});
