import { lazy } from "react";

export const supportedLocales = [
  "en", // the first one is the default locale
  // "en-gb",
  "zh-cn",
  "zh-tw",
  // "es",
  // "de",
  // "fr",
  // "ja",
  // "ko",
  // "ar",
  // "ru",
  // "pt",
  // "it",
  // "nl",
  // "pl",
  // "tr",
  // "th"
];

const Home = lazy(() => import("routes/Home"));
const Faq = lazy(() => import("routes/Faq"));
const Privacy = lazy(() => import("routes/Privacy"));
const Terms = lazy(() => import("routes/Terms"));
const Acknowledgements = lazy(() => import("routes/Acknowledgements"));
const NotFound = lazy(() => import("routes/NotFound"));

const ImpactReport2020 = lazy(() => import("routes/news/ImpactReport2020"));
const ImpactReport2021 = lazy(() => import("routes/news/ImpactReport2021"));
const ImpactReport2022 = lazy(() => import("routes/news/ImpactReport2022"));
const Widget = lazy(() => import("routes/tips/Widget"));
const StylizeGarden = lazy(() => import("routes/tips/StylizeGarden"));
const AutoStopwatch = lazy(() => import("routes/tips/AutoStopwatch"));
const AppWhitelist = lazy(() => import("routes/tips/AppWhitelist"));

export const routes = {
  // Each key specifies the url after "/"
  "": Home,
  "faq": Faq,
  "privacy": Privacy,
  "terms": Terms,
  "acknowledgements": Acknowledgements,

  "news/impact-report": ImpactReport2022,
  "news/impact-report-2021": ImpactReport2021,
  "news/impact-report-2020": ImpactReport2020,
  "tips/widget": Widget,
  "tips/stylize-garden": StylizeGarden,
  "tips/auto-stopwatch": AutoStopwatch,
  "tips/app-whitelist": AppWhitelist,

  "404": NotFound
};
